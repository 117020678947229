<template>
  <v-snackbar v-model="isSnackbarVisible" top right @input="onSnackbarInput" :color="color">
    {{ message }}
    <!-- <template #action="{ attrs }">
      <v-btn
        color="error"
        text
        v-bind="attrs"
        @click="isSnackbarVisible.value = false"
      >
        Close
      </v-btn>
    </template> -->
  </v-snackbar>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    message: {
      default: null,
      type: String
    },
    visible: {
      default: false,
      type: Boolean
    },
    color: {
      type: String,
      default:'primary'
    }
  },
  setup(props, { emit }) {
    const { visible } = props;
    const isSnackbarVisible = ref(visible);
    
    const onSnackbarInput = () => {
      emit('onSnackbarInput');
    }

    watch(() => props.visible, (visible) => { 
      isSnackbarVisible.value = visible;
    })

    return {
      isSnackbarVisible,

      onSnackbarInput
    }
  }
};
</script>

<style></style>
