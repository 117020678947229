<template>
  <div class="auth-wrapper auth-v2">
    <div class="wrapper__app-bar-i18n">
      <app-bar-i18n></app-bar-i18n>
    </div>

    <div class="auth-inner">
      <GeneralLogo />

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="
                require(`@/assets/images/misc/mask-v2-${
                  $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
              "
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary my-2"
                  ></p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="registerForm" @submit.prevent="handleFormSubmit">
                    <FormTextField
                      v-model="formData.first_name"
                      :label="t('FirstName')"
                      :placeholder="t('FirstName')"
                      :rules="[validators.required, validators.alphaValidator]"
                      class="mb-6"
                    />

                    <FormTextField
                      v-model="formData.last_name"
                      :label="t('LastName')"
                      :placeholder="t('LastName')"
                      :rules="[validators.required, validators.alphaValidator]"
                      class="mb-6"
                    />

                    <FormTextField
                      :disabled="queryInvite"
                      v-model="formData.email"
                      :label="t('Email')"
                      :placeholder="t('Email')"
                      :rules="[validators.required, validators.emailValidator]"
                      class="mb-6"
                    />

                    <FormTextFieldPassword
                      v-model="formData.password"
                      :label="t('Password')"
                      :placeholder="t('Password')"
                      :rules="[validators.required]"
                      class="mb-6"
                    />

                    <SelectCountries
                      v-model="formData.country_id"
                      :disabled="queryInvite"
                      :rules="[(v) => !!v || '']"
                    />

                    <v-checkbox
                      v-model="formData.terms"
                      :value="true"
                      :rules="[validators.isSelected]"
                      hide-details
                      class="mt-6"
                    >
                      <template #label>
                        <div class="d-flex align-center flex-wrap">
                          {{ t("accept") }}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                target="_blank"
                                :href="t('url-accept')"
                                @click.stop
                                v-on="on"
                              >
                                {{ t("acceptTerms") }}
                              </a>
                            </template>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>

                    <v-btn block color="primary" type="submit" class="mt-6">
                      {{ t("Register") }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p class="mb-0 me-2">
                    {{ t("AskSignin") }}
                  </p>
                  <router-link :to="{ name: 'auth-login' }">
                    {{ t("Signin") }}
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GeneralLogo from "@/components/general/Logo";
import FormTextField from "@/components/forms/TextField";
import FormTextFieldPassword from "@/components/forms/TextFieldPassword";
import SelectCountries from "@/components/general/SelectCountries";
import Snackbar from "@/components/general/Snackbar";
import AppBarI18n from "@core/layouts/components/app-bar/AppBarI18n";

// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
// eslint-disable-next-line object-curly-newline
import {
  required,
  emailValidator,
  passwordValidator,
  alphaValidator,
  isSelected,
} from "@core/utils/validation";
import {
  ref,
  reactive,
  getCurrentInstance,
  onUpdated,
  onMounted,
  computed
} from "@vue/composition-api";
import api from "@/services";
import router from "@/router";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { loadLanguageAsync } from "@/plugins/i18n";

export default {
  components: {
    GeneralLogo,
    FormTextField,
    FormTextFieldPassword,
    SelectCountries,
    Snackbar,
    AppBarI18n,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { t } = useUtilsI18n();
    const signupErrorMessage = ref(t("SignupError"));
    const signupSuccessMessage = ref(t("SignupSuccess"));

    // Template Refs
    const registerForm = ref(null);

    const formData = reactive({
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      country_id: null,
      terms: null,
      token_invitation: null,
    });
    
    const queryInvite = computed(() => {
      return formData.token_invitation;
    })

    const handleFormSubmit = async () => {
      const isFormValid = registerForm.value.validate();

      if (!isFormValid) return;

      try {
        const { data: response } = await api.register(formData);
        const { access_token: accessToken, user } = response;
        const { country } = user;

        if (accessToken) {
          const defaultLocale = country
            ? country.code.toString().toLowerCase()
            : "es";
          loadLanguageAsync(defaultLocale);

          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("userData", JSON.stringify(user));

          vm.$alert(signupSuccessMessage.value, null, "success");

          setTimeout(() => {
            router.push("/");
          }, 1000);
        }
      } catch (error) {
        const { data, status } = error.response;
        const message = data?.message && status !== 422 ? data?.message : signupErrorMessage.value;
        vm.$alert(message, null, "error");
      }
    };

    onUpdated(() => {
      signupErrorMessage.value = t("SignupError");
      signupSuccessMessage.value = t("SignupSuccess");
    });

    onMounted(() => {
      const { country_id, email, token_invitation } = router.currentRoute.query;
      formData.email = email;
      formData.token_invitation = token_invitation;
      formData.country_id = parseInt(country_id);
    })

    return {
      // Template Refs
      registerForm,
      formData,
      queryInvite,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        alphaValidator,
        isSelected,
      },

      handleFormSubmit,
      t,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/preset/preset/pages/auth.scss";
</style>
